<template>
  <v-row>
    <v-col cols="7">
      <v-card>
        <v-card-text>
          <h2 class="mb-2" style="text-transform: capitalize">{{ pelatihan.nama }}</h2>
          <p v-html="pelatihan.deskripsi"></p>
          <h3 class="mb-1">Detail Stakeholder Pelatihan:</h3>
          <p v-html="pelatihan.detail_stakeholder"></p>
          <v-row>
            <v-col>
              <v-btn small color="primary" block @click="addPendaftaran(item)"> Daftar </v-btn>
            </v-col>
            <v-col>
              <router-link :to="{ name: 'PesertaPelatihan' }">
                <v-btn small color="warning" block> Kembali </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-tabs v-model="tab" align-with-title>
        <v-tab key="agenda">
          Agenda
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="agenda">
          <v-card>
            <v-card-text>
              <ul>
                <li v-for="(item, index) in table.items" :key="item.id">
                  <b>Hari ke {{ index + 1 }}, {{ formatDate(item.tanggal) }}</b>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(itemMateri, indexMateri) in item.materis" :key="itemMateri.id">
                          <td>
                            <span v-if="itemMateri.file_url != ''">
                              <a v-if="itemMateri.tipe == 'MATERI'" :href="itemMateri.file_url" target="_blank">
                                <v-chip x-small color="primary">{{ itemMateri.tipe }}</v-chip>
                                {{ itemMateri.nama }}
                              </a>
                              <a v-else :href="itemMateri.file_url" target="_blank">
                                <v-chip x-small color="warning">{{ itemMateri.tipe }}</v-chip>
                                {{ itemMateri.nama }}
                              </a>
                            </span>
                            <span v-else>
                              <v-chip v-if="itemMateri.tipe == 'MATERI'" x-small color="primary">{{
                                itemMateri.tipe
                              }}</v-chip>
                              <v-chip v-else x-small color="warning">{{ itemMateri.tipe }}</v-chip>
                              {{ itemMateri.nama }}
                            </span>
                          </td>
                          <td>
                            <router-link
                              v-if="itemMateri.tipe == 'TUGAS'"
                              :to="{
                                name: 'PesertaPelatihanTugas',
                                params: { id_pelatihan: id_pelatihan, id_materi: itemMateri.id, hari_ke: index + 1 },
                              }"
                            >
                              Lihat
                            </router-link>
                            <v-icon v-if="itemMateri.file_url == ''" small @click="editMateri(indexMateri, itemMateri)">
                              {{ icons.mdiLock }}
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock } from '@mdi/js'

export default {
  components: {},
  data() {
    return {
      id_pelatihan: this.$route.params.id,
      pelatihan: {},
      icons: {
        mdiEye,
        mdiPencil,
        mdiMagnify,
        mdiPlus,
        mdiTrashCan,
        mdiLock,
      },
      table: {
        items: [],
      },
      tab: null,
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getPelatihan()
    this.getAgendasPesertaDaftar()
  },
  methods: {
    getPelatihan() {
      const fmData = new FormData()
      fmData.append('id_pelatihan', this.id_pelatihan)
      axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
        .then(response => {
          this.pelatihan = response.data
        })
        .catch(error => {
          alert(error)
        })
    },
    addPendaftaran(item) {
      const fmData = new FormData()
      fmData.append('id_pelatihan', this.id_pelatihan)
      axiosPostAuth('api/Pelatihan/addPendaftaran', fmData)
        .then(response => {
          if (response.code === 200) {
            alert(response.message)
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
    getAgendasPesertaDaftar() {
      const fmData = new FormData()
      fmData.append('id_pelatihan', this.id_pelatihan)
      axiosPostAuth('api/Pelatihan/getAgendasPesertaDaftar', fmData)
        .then(response => {
          this.table.items = response.data
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
